var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-table',{ref:"adminsList",attrs:{"stripe":"","border":"","height":"100%","width":"100%","data":_vm.value,"row-class-name":_vm.tableRowClassName},on:{"selection-change":function($event){return _vm.$emit('selection-change', $event)}}},[_c('el-table-column',{attrs:{"type":"index","index":_vm.index,"width":"50"}}),_c('el-table-column',{attrs:{"type":"selection","width":"44"}}),_c('el-table-column',{attrs:{"sortable":"","min-width":"100","prop":"lastName","label":"Last name","sort-method":function (a, b) { return a.lastName.localeCompare(b.lastName); }}}),_c('el-table-column',{attrs:{"prop":"firstName","label":"First name","width":"180"}}),_c('el-table-column',{attrs:{"prop":"subdivision.fullName","label":"Subdivision","min-width":"120"}}),_c('el-table-column',{attrs:{"prop":"position.name","label":"Position","width":"180"}}),_c('el-table-column',{attrs:{"prop":"role.displayName","label":"Role","width":"200"}}),_c('el-table-column',{attrs:{"prop":"isActive","label":"Status","width":"80"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.isActive ? 'Active' : 'Disabled')+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"contacts","label":"Contact Info","width":"180"}}),_c('el-table-column',{attrs:{"label":"Operations","width":"280"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(!row.deletedAt)?_c('router-link',{staticClass:"mr-1",attrs:{"to":{
          name: 'admin.edit',
          params: { id: row.id },
        }}},[_c('el-button',{attrs:{"plain":"","type":"primary","icon":"el-icon-edit"}},[_vm._v("Edit")])],1):_vm._e(),(!row.deletedAt)?_c('el-button',{attrs:{"plain":"","icon":row.isActive ? 'el-icon-video-pause' : 'el-icon-video-play',"type":row.isActive ? 'warning' : 'success'},on:{"click":function($event){return _vm.$emit('toggle-activation', row)}}},[_vm._v(" "+_vm._s(row.isActive ? 'Disable' : 'Enable')+" ")]):_vm._e(),(row.deletedAt)?_c('el-button',{attrs:{"plain":"","type":"info","icon":"el-icon-thumb"},on:{"click":function($event){return _vm.$emit('restore', { id: row.id, index: _vm.index })}}},[_vm._v(" Restore ")]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }