import axios from 'axios'
import { errNotify, getTimeZone, env } from '@/services'

axios.defaults.baseURL = env.baseURL
axios.defaults.withCredentials = true
axios.defaults.headers.common['X-timezone'] = getTimeZone()

axios.interceptors.response.use(
  response => response,
  async error => {
    if (error.response && error.response.status !== 404) {
      errNotify(error)
    } else {
      console.error(error)
    }

    return Promise.reject(error)
  },
)

export const GET = (url, options) => axios.get(url, options)
export const POST = (url, data, options) => axios.post(url, data, options)
export const PUT = (url, data, options) => axios.put(url, data, options)
export const DELETE = (url, options) => axios.delete(url, options)
