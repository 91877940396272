export default {
  message: 'Домашня сторінка',

  langCode: 'UA',
  langName: 'Українська',

  routes: {
    management: 'Управління',
    analytics: 'Аналітика',
    payments: 'Платежі',
    corporations: 'Корпорації',
    subdivisions: 'Підрозділи',
    admins: 'Адміністратори',
    users: 'Працівники',
    positions: 'Посади',
    'testing-aims': 'Мета тестування',
    logout: 'Вихід',
    login: 'Вхід',
  },

  core: {
    about: 'Про D.O.T.S.',
    service: 'Сервіс',
    copyright: 'Авторське право 2021 D.O.T.S. ТОВ',
    rightsReserved: 'Усі права захищені'
  },

  auth: {
    logoutConfirm: 'Ви впевнені що бажаєте вийти з системи?',
  },

  filters: {
    search: 'Пошук...',
    corporation: 'Корпорація',
    subdivision: 'Підрозділ',
    position: 'Посада',
    status: 'Статус',
    paymentPlan: 'Тарифний план',
    currentEvent: 'Поточна подія',
    testingAim: 'Мета тестування',
    showDeleted: 'Показати видалене',
    showChildren: 'Показати дочірні',
  },

  tableLabels: {
    total: 'Загалом',
  },

  buttons: {
    save: 'Зберегти',
    cancel: 'Скасувати',
    login: 'Увійти',
    signup: 'Зареєструватися',
    clearFilters: 'Очистити фільтр',
    add: 'Додати',
    delete: 'Видалити',
    confirm: 'Підтвердити',
  },

  form: {
    name: {
      login: 'Увійти',
      signup: 'Зареєструватися',
      edit: 'Редагувати профіль',
      create: 'Створити профіль',
    },
    email: {
      label: 'Електронна пошта',
      placeholder: 'Введіть електронну пошту',
    },
    password: {
      label: 'Пароль',
      placeholder: 'Введіть пароль',
      temporaryPlaceholder: 'Введіть тимчасовий пароль',
    },
    confirmPassword: {
      label: 'Підтвердити пароль',
      placeholder: 'Підтвердіть пароль',
    },
    firstName: {
      label: 'Імʼя',
      placeholder: 'Ваше імʼя',
    },
    lastName: {
      label: 'Прізвище',
      placeholder: 'Введіть прізвище',
    },
    birthday: {
      label: 'Дата народження',
    },
    gender: {
      label: 'Стать',
      male: 'Чоловік',
      female: 'Жінка',
    },
    subdivision: {
      label: 'Підрозділ',
      name: 'Назва',
      placeholder: 'Введіть назву підрозділу',
    },
    city: {
      label: 'Місто',
      placeholder: 'Введіть назву міста',
    },
    parentSubdivision: {
      label: 'Підпорядковується до:',
      name: 'Назва',
      placeholder: 'Оберіть підрозділ якому буде підпорядковуватись',
    },
    mainSubdivision: {
      name: 'Головне управління',
      placeholder: 'Введіть назву головного управління',
    },
    position: {
      label: 'Посада',
      name: 'Назва посади',
      placeholder: 'Введіть назву посади',
    },
    testingAim: {
      label: 'Мета тестування',
      name: 'Назва',
      placeholder: 'Введіть назву мети тестування',
    },
    targetPosition: {
      label: 'Цільова посада',
    },
    notes: {
      label: 'Примітки',
    },
    role: {
      label: 'Роль',
    },
    contacts: {
      label: 'Контакти',
    },
    corporation: {
      label: 'Компанія',
      name: {
        label: 'Назва компанії',
        placeholder: 'Введіть назву вашої компанії',
      },
    },
    description: {
      label: 'Опис',
      placeholder: 'Коротко опишіть вашу компанію',
    },
    paymentPlan: {
      label: 'Тарифний план',
      placeholder: 'Оберіть тарифний план',
    },
  },

  el: {
    datepicker: {
      placeholder: 'Будь-ласка оберіть дату вашого народження',
      now: 'Зараз',
      today: 'Сьогодні',
      cancel: 'Відміна',
      clear: 'Очистити',
      confirm: 'OK',
      start: 'Початок',
      end: 'Завершення',
      selectDate: 'Обрати дату',
      selectTime: 'Обрати час',
      startDate: 'Дата початку',
      startTime: 'Час початку',
      endDate: 'Дата завершення',
      endTime: 'Час завершення',
      prevYear: 'Попередній рік',
      nextYear: 'Наступний рік',
      prevMonth: 'Попередній місяць',
      nextMonth: 'Наступний місяць',
      year: '',
      month1: 'Січень',
      month2: 'Лютий',
      month3: 'Березень',
      month4: 'Квітень',
      month5: 'Травень',
      month6: 'Червень',
      month7: 'Липень',
      month8: 'Серпень',
      month9: 'Вересень',
      month10: 'Жовтень',
      month11: 'Листопад',
      month12: 'Грудень',
      weeks: {
        sun: 'Нд',
        mon: 'Пн',
        tue: 'Вт',
        wed: 'Ср',
        thu: 'Чт',
        fri: 'Пт',
        sat: 'Сб',
      },
      months: {
        jan: 'Січ',
        feb: 'Лют',
        mar: 'Бер',
        apr: 'Кві',
        may: 'Тра',
        jun: 'Чер',
        jul: 'Лип',
        aug: 'Сер',
        sep: 'Вер',
        oct: 'Жов',
        nov: 'Лис',
        dec: 'Гру',
      },
    },
    select: {
      noData: 'Немає даних',
      placeholder: 'Обрати',
      noMatch: 'Не знайдено',
    },
    table: {
      emptyText: 'Немає даних',
    },
  },
}
