<template>
  <section class="page">
    <div v-if="isLoading">isLoading</div>
    <el-tabs v-else type="border-card">
      <el-tab-pane>
        <span slot="label"><i class="el-icon-notebook-2"></i> Profile </span>
        <PositionForm :position="position" @submit="submit" />
      </el-tab-pane>
      <el-tab-pane v-if="$route.params.id" label="Actions history">
        <span slot="label"><i class="el-icon-time"></i> Actions history </span>
        Actions history
      </el-tab-pane>
    </el-tabs>
  </section>
</template>

<script>
import { notify, errNotify, getDefault } from '@/services'
import PositionForm from './components/PositionForm'

export default {
  name: 'PositionPage',

  props: {
    corporationId: { type: Number, required: false },
  },

  components: {
    PositionForm,
  },

  data() {
    return {
      isLoading: true,
      position: null,
    }
  },

  created() {
    this.load()
  },

  methods: {
    async load() {
      this.isLoading = true

      const defaultPosition = {
        ...getDefault('position'),
        corporationId: this.corporationId,
      }

      try {
        this.position = this.$route.params.id
          ? await this.$store.dispatch('position/get', {
            id: this.$route.params.id,
          })
          : defaultPosition
      } catch (e) {
        this.position = defaultPosition
        errNotify(e)
      } finally {
        this.isLoading = false
      }
    },

    async submit(formData) {
      this.isLoading = true
      try {
        // TODO: implements update only changed fields
        this.position = formData.id
          ? await this.$store.dispatch('position/update', formData)
          : await this.$store.dispatch('position/create', formData)

        notify({
          type: 'success',
          title: 'Success',
          message: `Position ${formData.name} ${
            formData.id ? 'updated' : 'created'
          } successfully`,
        })

        await this.$router.push({ name: 'positions' })

        return this.position
      } catch (e) {
        return errNotify(e)
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.page {
  min-height: 100%;
  display: flex;
  width: 100%;
  margin: 0 auto;
}
</style>
