<template>
  <el-row class="admin-filters table-filter">
    <div class="mr-1">
      <el-button plain type="danger" @click="$emit('clear-filters')">
        {{$t('buttons.clearFilters')}}
      </el-button>
    </div>

    <div>
      <el-select
        filterable
        default-first-option
        :placeholder="$t('filters.corporation')"
        :value="value.corporation"
        @change="emit('corporation', $event)"
      >
        <el-option
          v-for="c in corporations"
          :key="c.id"
          :label="c.name"
          :value="c.id"
        />
      </el-select>

      <el-input
        clearable
        class="item"
        :placeholder="$t('filters.search')"
        :value="value.search"
        @input="emit('search', $event)"
      />

      <el-select
        clearable
        filterable
        :placeholder="$t('filters.subdivision')"
        :value="value.subdivision"
        @change="emit('subdivision', $event)"
      >
        <el-option
          v-for="s in subdivisions"
          :key="s.id"
          :label="s.fullName"
          :value="s.id"
        />
      </el-select>

      <el-select
        clearable
        filterable
        :placeholder="$t('filters.position')"
        :value="value.position"
        @change="emit('position', $event)"
      >
        <el-option
          v-for="p in positions"
          :key="p.id"
          :label="p.name"
          :value="p.id"
        />
      </el-select>

      <el-select
        clearable
        filterable
        :placeholder="$t('filters.status')"
        :value="value.status"
        @change="emit('status', $event)"
      >
        <el-option
          v-for="us in statuses"
          :key="us.id"
          :label="us.name"
          :value="us.id"
        />
      </el-select>

      <el-checkbox
        border
        :value="value.showDeleted"
        @change="emit('showDeleted', $event)"
      >
        {{ $t('filters.showDeleted') }}
      </el-checkbox>
    </div>
  </el-row>
</template>

<script>
import { mapGetters } from 'vuex'
import { getDefault } from '@/services'

export default {
  name: 'AdminFilters',

  props: {
    value: { type: Object, required: true },
  },

  data: () => ({
    statuses: getDefault('activityStatuses'),
  }),

  computed: {
    ...mapGetters({
      corporations: 'corporation/corporations',
      subdivisions: 'subdivision/subdivisions',
      positions: 'position/positions',
    }),
  },

  methods: {
    emit(name, value) {
      this.$emit(`update:${name}`, value)
    },
  },
}
</script>
