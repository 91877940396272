<template>
  <section class="payments-page">
    <h1>Payments</h1>
  </section>
</template>

<script>
export default {
  name: 'PaymentsPage',
}
</script>

<style lang="scss" scoped></style>
