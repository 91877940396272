import cloneDeep from 'lodash/cloneDeep'
import debounce from 'lodash/debounce'
import get from 'lodash/get'
import env from './envConstants'

const pluck = (arr, prop) => (
  Array.isArray(arr) ? arr.map(a => a[prop]) : []
)

const head = list => (
  Array.isArray(list) || typeof list === 'string' ? list[0] : undefined
)

const getIndex = page => (page - 1) * env.PAGINATION_SIZE + 1

const indexToPage = index => Math
  .round(index / (env.PAGINATION_SIZE + 1) + 1)

export {
  cloneDeep,
  debounce,
  get,
  pluck,
  head,
  getIndex,
  indexToPage,
}
