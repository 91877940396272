<template>
  <div class="t-page">
    TPage
  </div>
</template>

<script>
export default {
  name: 'TPage',

  data() {
    return {}
  },

  methods: {},
}
</script>

<style scoped lang="scss">

</style>
