<template>
  <section class="corporation-form">
    <h3>
      {{ `${formData.id ? $t('form.name.edit') : $t('form.name.create')}` }}
    </h3>
    <el-form
      ref="formData"
      label-width="200px"
      size="small"
      :rules="rules"
      :model="formData"
      @submit.native.prevent="submit"
    >
      <el-form-item prop="name" :label="$t('form.corporation.name.label')">
        <el-input
          name="Company Name"
          :placeholder="$t('form.corporation.name.placeholder')"
          v-model="formData.name"
        />
      </el-form-item>

      <el-form-item
        prop="mainSubdivision.name"
        :label="$t('form.mainSubdivision.name')"
      >
        <el-input
          name="Main Subdivision"
          :disabled="Boolean(formData.id)"
          :placeholder="$t('form.mainSubdivision.placeholder')"
          v-model="formData.mainSubdivision.name"
        />
      </el-form-item>

      <el-form-item prop="mainSubdivision.city" :label="$t('form.city.label')">
        <el-input
          name="City"
          :disabled="Boolean(formData.id)"
          :placeholder="$t('form.city.placeholder')"
          v-model="formData.mainSubdivision.city"
        />
      </el-form-item>

      <el-form-item prop="description" :label="$t('form.description.label')">
        <el-input
          type="textarea"
          :placeholder="$t('form.description.placeholder')"
          :autosize="{ minRows: 2 }"
          v-model="formData.description"
        >
        </el-input>
      </el-form-item>

      <el-form-item>
        <el-switch
          style="display: block"
          v-model="formData.isActive"
          active-color="#13ce66"
          inactive-color="#ff4949"
          active-text="Enable"
          inactive-text="Disable"
        />
      </el-form-item>

      <el-form-item prop="paymentPlanId" :label="$t('form.paymentPlan.label')">
        <el-select
          filterable
          :placeholder="$t('form.paymentPlan.placeholder')"
          v-model="formData.paymentPlanId"
        >
          <el-option
            v-for="pp in paymentPlans"
            :key="pp.id"
            :label="pp.name"
            :value="pp.id"
          />
        </el-select>
      </el-form-item>

      <el-form-item class="actions">
        <router-link class="mr-1" :to="{ name: 'corporations' }">
          <el-button plain type="danger">{{ $t('buttons.cancel') }}</el-button>
        </router-link>

        <el-button
          plain
          type="primary"
          class="submit-btn"
          :disabled="!dirty"
          @click.prevent.native="submit"
        >
          {{ $t('buttons.save') }}
        </el-button>
      </el-form-item>
    </el-form>
  </section>
</template>

<script>
import { cloneDeep, errNotify } from '@/services'

export default {
  name: 'CorporationForm',
  props: {
    corporation: { type: Object, required: true },
  },
  data() {
    return {
      formData: null,
      dirty: false,
      rules: {
        name: [
          {
            required: true,
            message: 'Company name is required',
            trigger: 'blur',
          },
        ],
        'mainSubdivision.name': [
          {
            required: true,
            message: 'Head office name is required',
            trigger: 'blur',
          },
        ],
      },
    }
  },
  created() {
    this.formData = cloneDeep(this.corporation)
  },
  computed: {
    paymentPlans() {
      return this.$store.getters['dependency/paymentPlans']
    },
  },
  watch: {
    formData: {
      handler(newVal, oldVal) {
        this.dirty = newVal === oldVal
      },
      deep: true,
    },
  },
  methods: {
    submit() {
      this.$refs.formData.validate(async valid => {
        if (!valid) return
        try {
          await this.$emit('submit', this.formData)
        } catch (e) {
          errNotify(e)
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.corporation-form {
  .el-date-editor,
  .el-select {
    width: 100%;
  }

  .actions {
    margin-top: 1rem;

    .submit-btn {
      min-width: 200px;
      margin-top: 20px;
    }
  }
}
</style>
