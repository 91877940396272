import { Notification } from 'element-ui'

const TYPE = {
  success: 'success',
  warning: 'warning',
  info: 'info',
  error: 'error',
}

export const notify = ({
  title = '',
  message = '',
  type = 'error',
  duration = 5500,
  offset = 42,
},
isHtml = false) => {
  if (!TYPE[type]) throw new Error('Wrong notify type')

  Notification[TYPE[type]]({
    title,
    message,
    duration,
    offset,
    dangerouslyUseHTMLString: isHtml,
    position: 'bottom-right',
  })
}

export const errNotify = e => {
  const status = e.response ? `: ${e.response.status}` : ''
  const message = e.response ? e.response.data.message : e
  notify({
    title: `Error ${status}`,
    message,
  })
  console.error('e: ', e)
  console.error('e.response: ', e.response)
}
