<template>
  <section class="corporation-page">
    <div v-if="isLoading">isLoading</div>
    <el-tabs v-else type="border-card">
      <el-tab-pane>
        <span slot="label"><i class="el-icon-notebook-2"></i> Profile </span>
        <CorporationForm
          class="corporation-form"
          :corporation="corporation"
          @submit="submit"
        />
      </el-tab-pane>
    </el-tabs>
  </section>
</template>

<script>
import { notify, errNotify, getDefault } from '@/services'
import CorporationForm from './components/CorporationForm'

export default {
  name: 'CorporationPage',
  components: {
    CorporationForm,
  },
  data() {
    return {
      isLoading: true,
      corporation: null,
    }
  },
  created() {
    this.load()
  },
  methods: {
    async load() {
      this.isLoading = true
      try {
        this.corporation = this.$route.params.id
          ? await this.$store.dispatch('corporation/get', {
            id: this.$route.params.id,
          })
          : getDefault('corporation')
      } catch (e) {
        this.corporation = getDefault('corporation')
        errNotify(e)
      } finally {
        this.isLoading = false
      }
    },

    async submit(formData) {
      this.isLoading = true
      try {
        // TODO: implements update only changed fields
        this.corporation = formData.id
          ? await this.$store.dispatch('corporation/update', formData)
          : await this.$store.dispatch('corporation/create', formData)

        notify({
          type: 'success',
          title: 'Success',
          message: `Corporation ${formData.name} ${
            formData.id ? 'updated' : 'created'
          } successfully`,
        })

        await this.$router.push({ name: 'corporations' })

        return this.corporation
      } catch (e) {
        return errNotify(e)
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.corporation-page {
  min-height: 100%;
  display: flex;
  width: 100%;
  margin: 0 auto;

  .corporation-form {
    // margin: 0 auto;
    text-align: left;
    // background-color: #fec64011;
  }
}
</style>
