export default {
  message: 'Home page',

  langCode: 'EN',
  langName: 'English',

  routes: {
    management: 'Management',
    analytics: 'Analytics',
    payments: 'Payments',
    corporations: 'Corporations',
    subdivisions: 'Subdivisions',
    admins: 'Admins',
    users: 'Users',
    positions: 'Positions',
    'testing-aims': 'Testing aims',
    logout: 'Logout',
    login: 'Login',
  },

  core: {
    about: 'About D.O.T.S.',
    service: 'Service',
    copyright: 'Copyright 2021 D.O.T.S. LLC',
    rightsReserved: 'All rights reserved'
  },

  auth: {
    logoutConfirm: 'Are you sure you want to logout?',
  },

  filters: {
    search: 'Search...',
    corporation: 'Corporation',
    subdivision: 'Subdivision',
    position: 'Position',
    status: 'Status',
    paymentPlan: 'Payment plan',
    testingAim: 'Testing aim',
    currentEvent: 'Current event',
    showDeleted: 'Show deleted',
    showChildren: 'Show children',
  },

  tableLabels: {
    total: 'Total',
  },

  buttons: {
    save: 'Save',
    cancel: 'Cancel',
    login: 'Login',
    signup: 'Signup',
    clearFilters: 'Clear filters',
    add: 'Add',
    delete: 'Delete',
    confirm: 'Confirm',
  },

  form: {
    name: {
      login: 'Login',
      signup: 'Signup',
      edit: 'Edit profile',
      create: 'Create profile',
    },
    email: {
      label: 'Email',
      placeholder: 'Please input email',
    },
    password: {
      label: 'Password',
      placeholder: 'Please input password',
      temporaryPlaceholder: 'Please input temporary password',
    },
    confirmPassword: {
      label: 'Confirm password',
      placeholder: 'Please confirm password',
    },
    firstName: {
      label: 'First name',
      placeholder: 'Please input first name',
    },
    lastName: {
      label: 'Last name',
      placeholder: 'Please input last name',
    },
    birthday: {
      label: 'Birthday',
    },
    gender: {
      label: 'Gender',
      male: 'Male',
      female: 'Female',
    },
    subdivision: {
      label: 'Subdivision',
      name: 'Subdivision name',
      placeholder: 'Please input subdivision name',
    },
    city: {
      label: 'City',
      placeholder: 'Please input city name',
    },
    parentSubdivision: {
      label: 'Parent subdivision',
      name: 'Parent subdivision name',
      placeholder: 'Please select parent subdivision',
    },
    mainSubdivision: {
      name: 'Head office',
      placeholder: 'Please input head office name',
    },
    position: {
      label: 'Position',
      name: 'Position name',
      placeholder: 'Please input position name',
    },
    testingAim: {
      label: 'Testing aim',
      name: 'Testing aim name',
      placeholder: 'Please input testing aim name',
    },
    targetPosition: {
      label: 'Target position',
    },
    notes: {
      label: 'Notes',
    },
    role: {
      label: 'Role',
    },
    contacts: {
      label: 'Contacts',
    },
    corporation: {
      label: 'Corporation',
      name: {
        label: 'Company name',
        placeholder: 'Please type your company name',
      },
    },
    description: {
      label: 'Description',
      placeholder: "Please input your company's description",
    },
    paymentPlan: {
      label: 'Payment plan',
      placeholder: 'Please select payment plan',
    },
  },

  el: {
    datepicker: {
      placeholder: 'Please select your birthday',
      now: 'Now',
      today: 'Today',
      cancel: 'Cancel',
      clear: 'Clear',
      confirm: 'OK',
      start: 'Start',
      end: 'End',
      selectDate: 'Select date',
      selectTime: 'Select time',
      startDate: 'Start date',
      startTime: 'Start time',
      endDate: 'End date',
      endTime: 'End time',
      prevYear: 'Previous year',
      nextYear: 'Next year',
      prevMonth: 'Previous month',
      nextMonth: 'Next month',
      year: '',
      month1: 'January',
      month2: 'February',
      month3: 'March',
      month4: 'April',
      month5: 'May',
      month6: 'June',
      month7: 'July',
      month8: 'August',
      month9: 'September',
      month10: 'October',
      month11: 'November',
      month12: 'December',
      weeks: {
        sun: 'Sun',
        mon: 'Mon',
        tue: 'Tue',
        wed: 'Wed',
        thu: 'Thu',
        fri: 'Fri',
        sat: 'Sat',
      },
      months: {
        jan: 'Jan',
        feb: 'Feb',
        mar: 'Mar',
        apr: 'Apr',
        may: 'May',
        jun: 'Jun',
        jul: 'Jul',
        aug: 'Aug',
        sep: 'Sep',
        oct: 'Oct',
        nov: 'Nov',
        dec: 'Dec',
      },
    },
    select: {
      noData: 'No data',
      placeholder: 'Select',
      noMatch: 'No match',
    },
  },
}
