<template>
  <div class="app">
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>
import DefaultLayout from './DefaultLayout'

export default {
  name: 'App',

  components: {
    DefaultLayout,
  },

  computed: {
    layout() {
      const { layout } = this.$route.meta || {}
      return layout || 'DefaultLayout'
    },
  },
}
</script>

<style lang="scss" scoped>
.app {
  min-height: 100%;
  max-width: 1920px;
  margin: auto;
}
</style>
