import { get, set, createStore } from 'idb-keyval'

// stores
const filtersStore = createStore('dots-super-admin', 'filters')

// wrappers
export const setFiltersByAdminId = async (id, key, value) => {
  const filters = (await get(id, filtersStore)) || {}
  filters[key] = value
  return set(id, filters, filtersStore)
}
export const getFiltersByAdminId = async (id, key) => {
  const filters = await get(id, filtersStore)
  return filters ? filters[key] : null
}

const initFilters = async (id, key) => {
  try {
    return await getFiltersByAdminId(id, key)
  } catch (e) {
    console.info('initFilters error for key: ', key)
    console.info('initFilters error for id: ', id)
    console.error('initFilters Error: ', e)
    return e
  }
}

// initialization
export const initIdb = id => Promise.all([
  initFilters(id, 'corporationFilters'),
  initFilters(id, 'subdivisionFilters'),
  initFilters(id, 'positionFilters'),
  initFilters(id, 'adminFilters'),
  initFilters(id, 'userFilters'),
  initFilters(id, 'currentEventFilters'),
  initFilters(id, 'testingAimFilters'),
])
