<template>
  <el-menu
    class="nav-menu"
    mode="horizontal"
    :default-active="$route.name"
    @select="select"
  >
    <el-row justify="space-between">
      <el-row>
        <el-submenu index="management" v-if="isAuth">
          <template slot="title">
            <i class="el-icon-receiving" />
            <span v-if="!maxmq('md')">{{ $t('routes.management') }}</span>
          </template>

          <el-menu-item
            v-for="item in managementItems"
            :key="item.name"
            :index="item.name"
            :route="{ name: item.name }"
          >
            <i :class="item.icon" />
            <span>{{ $t(`routes.${item.name}`) }}</span>
          </el-menu-item>
        </el-submenu>

        <el-menu-item
          v-for="item in menuItems"
          :key="item.name"
          :index="item.name"
          :route="{ name: item.name }"
        >
          <i :class="item.icon" />
          <span v-if="!maxmq('md')">{{ $t(`routes.${item.name}`) }}</span>
        </el-menu-item>
      </el-row>

      <el-row>
        <el-menu-item v-if="isAuth" @click="logout">
          <i class="el-icon-lock" />
          <span v-if="!maxmq('md')">{{ $t('routes.logout') }}</span>
        </el-menu-item>

        <el-menu-item v-else @click="$router.push({ name: 'login' })">
          <i class="el-icon-lock" />
          <span v-if="!maxmq('md')">{{ $t('routes.login') }}</span>
        </el-menu-item>

        <LocaleSwitcher />
      </el-row>
    </el-row>
  </el-menu>
</template>

<script>
import { errNotify } from '@/services'
import LocaleSwitcher from './LocaleSwitcher'

export default {
  name: 'NavMenu',
  components: {
    LocaleSwitcher,
  },
  computed: {
    isAuth() {
      return this.$store.getters['auth/isAuth']
    },
    managementItems() {
      return this.isAuth
        ? [
          { icon: 'el-icon-school', name: 'corporations' },
          { icon: 'el-icon-office-building', name: 'subdivisions' },
          { icon: 'el-icon-user-solid', name: 'admins' },
          { icon: 'el-icon-user', name: 'users' },
          { icon: 'el-icon-s-cooperation', name: 'positions' },
          { icon: 'el-icon-info', name: 'testing-aims' },
        ]
        : []
    },
    menuItems() {
      return this.isAuth
        ? [
          { icon: 'el-icon-s-data', name: 'analytics' },
          { icon: 'el-icon-bank-card', name: 'payments' },
        ]
        : []
    },
  },
  methods: {
    select(name) {
      if (name && !name.startsWith('btn')) {
        this.$router.push({ name })
      }
    },

    async logout() {
      const isAgree = await this.$confirm(this.$t('auth.logoutConfirm'), {
        type: 'warning',
      })

      if (isAgree) {
        try {
          await this.$store.dispatch('auth/logout')
          return this.$router.push({ name: 'login' })
        } catch (e) {
          return errNotify(e)
        }
      }
    },
  },
}
</script>
