import Vue from 'vue'
import Vuex from 'vuex'
import admin from './admin'
import corporation from './corporation'
import currentEvent from './currentEvent'
import dependency from './dependency'
import filters from './filters'
import position from './position'
import subdivision from './subdivision'
import auth from './auth'
import user from './user'
import testingAim from './testingAim'

Vue.use(Vuex)

// SET MODULES WITH NAMESPACE HERE:
admin.namespaced = true
corporation.namespaced = true
currentEvent.namespaced = true
dependency.namespaced = true
filters.namespaced = true
position.namespaced = true
subdivision.namespaced = true
auth.namespaced = true
user.namespaced = true
testingAim.namespaced = true

export default new Vuex.Store({
  state: () => ({
    locale: '',
    isAppLoaded: false,
    router: null,
  }),
  mutations: {
    setLocale: (state, payload) => { state.locale = payload },
    setIsAppLoaded: (state, payload) => { state.isAppLoaded = payload },
    setRouter: (state, router) => { state.router = router },
  },
  actions: {
    setLocale({ commit }, { locale }) {
      commit('setLocale', locale)
    },

    setIsAppLoaded({ commit }, isAppLoaded) {
      commit('setIsAppLoaded', isAppLoaded)
      return isAppLoaded
    },

    setRouter({ commit }, router) {
      commit('setRouter', router)
    },
  },
  getters: {
    locale: state => state.locale,
    isAppLoaded: state => state.isAppLoaded,
    router: state => state.router,
  },
  modules: {
    admin,
    corporation,
    currentEvent,
    dependency,
    filters,
    position,
    subdivision,
    auth,
    user,
    testingAim,
  },
})
