<template>
  <el-table
    stripe
    border
    ref="corporationsList"
    :data="value"
    height="100%"
    width="100%"
    :row-class-name="tableRowClassName"
    @selection-change="$emit('selection-change', $event)"
  >
    <el-table-column type="index" width="50" :index="index" />

    <el-table-column type="selection" width="44" />

    <el-table-column
      sortable
      min-width="200"
      prop="name"
      label="Company Name"
      :sort-method="(a, b) => a.name.localeCompare(b.name)"
    />

    <el-table-column prop="description" label="Description" min-width="150" />

    <el-table-column
      sortable
      width="150"
      prop="createdAt"
      label="Start Date"
    />

    <el-table-column prop="isActive" label="Status" width="80">
      <template slot-scope="{ row }">
        {{ row.isActive ? 'Active' : 'Disabled' }}
      </template>
    </el-table-column>

    <el-table-column
      sortable
      prop="users"
      label="Users"
      width="100"
      :sort-method="(a, b) => Number(a.users) - Number(b.users)"
    />

    <el-table-column
      sortable
      prop="admins"
      label="Admins"
      width="100"
      :sort-method="(a, b) => Number(a.admins) - Number(b.admins)"
    />

    <el-table-column
      sortable
      prop="uses_tests"
      label="Tests used"
      width="120"
      :sort-method="(a, b) => Number(a.uses_tests) - Number(b.uses_tests)"
    />

    <el-table-column
      sortable
      prop="updatedAt"
      label="Updated At"
      width="150"
    />

    <el-table-column
      min-width="100"
      prop="paymentPlan.name"
      label="Payment Plan"
    />

    <el-table-column label="Operations" width="200">
      <!-- TODO: implement operations -->
      <template slot-scope="{ row }">
        <router-link
          class="mr-1"
          v-if="!row.deletedAt"
          :to="{
            name: 'corporation.edit',
            params: { id: row.id },
          }"
        >
          <el-button plain type="primary" icon="el-icon-edit">Edit</el-button>
        </router-link>

        <el-button
          plain
          :icon="row.isActive ? 'el-icon-video-pause' : 'el-icon-video-play'"
          :type="row.isActive ? 'warning' : 'success'"
          v-if="!row.deletedAt"
          @click="$emit('toggle-activation', row)"
        >
          {{ row.isActive ? 'Disable' : 'Enable' }}
        </el-button>

        <el-button
          plain
          type="info"
          icon="el-icon-thumb"
          v-if="row.deletedAt"
          @click="$emit('restore', { id: row.id, index })"
        >
          Restore
        </el-button>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
export default {
  name: 'CorporationsTable',

  props: {
    index: { type: Number, required: true },
    value: { type: Array, required: true },
    selected: { type: Array, required: true },
  },

  methods: {
    // TODO: fix it
    // eslint-disable-next-line no-nested-ternary
    tableRowClassName: ({ row }) => (row.deletedAt
      ? 'deleted-row'
      : row.isActive
        ? 'success-row'
        : 'warning-row'),
  },
}
</script>

<!--TODO: move to global-->
<style lang="scss" scoped>
/deep/ .deleted-row {
  color: #f56c6c;
}

/deep/ .warning-row {
  color: #999;
}

/deep/ .success-row {
  color: #333;
}
</style>
