<template>
  <section class="subdivisions-page">
    <el-row class="mb-2" justify="space-between">
      <SubdivisionFilters
        class="subdivisions-filters"
        :value="filters"
        v-bind.sync="filters"
        @apply-filters="applyFilters"
        @clear-filters="clearFilters"
      />

      <div class="ml-1" v-if="!deleted">
        <el-button-group>
          <el-button
            plain
            type="success"
            icon="el-icon-plus"
            @click="$router.push({
              name: 'subdivision.new',
              params: { corporationId: filters.corporation },
            })"
          >
            {{ $t('buttons.add') }}
          </el-button>

          <el-button
            plain
            type="danger"
            icon="el-icon-delete"
            :disabled="!selected.length"
            @click="handleDelete"
          >
            {{ $t('buttons.delete') }}
          </el-button>
        </el-button-group>
      </div>
    </el-row>

    <div class="content mb-1">
      <SubdivisionsTable
        v-loading="isLoading"
        :index="index"
        :value="tableData"
        :selected="selected"
        @selection-change="selected = $event"
        @restore="restore"
      />
    </div>

    <el-row justify="center">
      <el-pagination
        background
        hide-on-single-page
        layout="prev, pager, next"
        :total="total"
        :current-page.sync="page"
        :page-size="PAGINATION_SIZE"
        @current-change="showCurrentPage"
      />
    </el-row>

    <div class="mt-1" v-if="total">
      {{ $t('tableLabels.total') }}: {{ total }}
    </div>
  </section>
</template>

<script>
import {
  errNotify,
  notify,
  getIndex,
  pluck,
  head,
  indexToPage,
  debounce,
  env,
  getDefault,
} from '@/services'
import SubdivisionFilters from './components/SubdivisionFilters'
import SubdivisionsTable from './components/SubdivisionsTable'

export default {
  name: 'SubdivisionsPage',

  components: {
    SubdivisionFilters,
    SubdivisionsTable,
  },

  data() {
    return {
      isLoading: true,
      filters: {},
      tableData: [],
      selected: [],
      total: 0,
      PAGINATION_SIZE: env.PAGINATION_SIZE,
      page: 1,
      deleted: false,
    }
  },

  computed: {
    index() {
      return getIndex(this.page)
    },
  },

  watch: {
    filters: {
      deep: true,
      handler() {
        if (this.isLoading) return null
        return this.applyFilters()
      },
    },
    selected: {
      deep: true,
      handler() {
        if (this.isLoading) return null

        if (this.filters.showChildren) {
          return this.selected.length === 1
            ? this.applyFilters()
            : notify({
              type: 'warning',
              title: 'Show children',
              message: 'For show children please select only one subdivision',
            })
        }
        return null
      },
    },
  },

  async mounted() {
    try {
      this.applyFilters = debounce(this.applyFilters, env.DEBOUNCE_TIME)
      await this.load()
    } catch (e) {
      console.error('mounted error: ', e)
    }
  },

  methods: {
    async restoreFilters() {
      try {
        const filters = await this.$store.dispatch('filters/get', {
          key: 'subdivisionFilters',
        })
        if (!filters) return null
        this.$set(this, 'filters', filters)
        return filters
      } catch (e) {
        return console.error(e)
      }
    },

    async storeFilters() {
      try {
        await this.$store.dispatch('filters/set', {
          key: 'subdivisionFilters',
          value: this.filters,
        })
      } catch (e) {
        console.error(e)
      }
    },

    async load() {
      this.isLoading = true
      try {
        const filters = await this.restoreFilters()
        if (!filters) return null

        const params = {
          limit: this.PAGINATION_SIZE,
          offset: (this.page - 1) * this.PAGINATION_SIZE,
          filters: this.filters,
        }

        const {
          total,
          results,
        } = await this.$store.dispatch('subdivision/fetchAll', { params })

        this.total = total
        this.tableData = results
        return results
      } catch (e) {
        return errNotify(e)
      } finally {
        this.isLoading = false
      }
    },

    async applyFilters() {
      this.isLoading = true
      try {
        this.page = 1
        this.deleted = this.filters.showDeleted

        if (this.filters.showChildren) {
          if (this.selected.length !== 1) {
            return notify({
              type: 'warning',
              title: 'Show children',
              message: 'For show children please select only one subdivision',
            })
          }
          this.filters.currentId = head(this.selected).id
        }

        await this.storeFilters()
        return await this.load()
      } catch (e) {
        return errNotify(e)
      } finally {
        this.isLoading = false
      }
    },

    async clearFilters() {
      this.isLoading = true
      try {
        const { corporation } = this.filters
        this.filters = {
          ...getDefault('subdivisionFilters'),
          corporation,
        }

        this.page = 1
        this.deleted = false
        await this.storeFilters()
        await this.load()
      } catch (e) {
        errNotify(e)
      } finally {
        this.isLoading = false
      }
    },

    async showCurrentPage(page) {
      try {
        this.page = page
        await this.load()
      } catch (e) {
        errNotify(e)
      } finally {
        this.isLoading = false
      }
    },

    async isUsed(selected) {
      const children = await Promise.all(
        selected.map(
          ({ id }) => this.$store.dispatch('subdivision/getChildren', { id }),
        ),
      )
      const hasAdminsOrUsers = selected.some(
        s => Number(s.admins) !== 0 || Number(s.users) !== 0,
      )
      const hasChildren = children.some(c => c.length !== 0)

      if (hasAdminsOrUsers) {
        notify(
          {
            type: 'warning',
            title: 'Some selected subdivision is not empty',
            // FIXME
            // eslint-disable-next-line
            message: 'Some subdivisions <b style="color:red">has admins or users</b>, please check it and reassign to another subdivision or delete they.',
          },
          true,
        )
      }
      if (hasChildren) {
        setTimeout(() => {
          notify(
            {
              type: 'warning',
              title: 'Some selected subdivision is not empty',
              // FIXME
              // eslint-disable-next-line
              message: 'Some subdivisions <b style="color:red">has children subdivision</b>, please check it and reassign to another subdivision or delete they.',
            },
            true,
          )
        }, 300)
      }
      return hasAdminsOrUsers || hasChildren
    },

    async handleDelete() {
      const isUsed = await this.isUsed(this.selected)
      if (isUsed) return false

      try {
        const ids = pluck(this.selected, 'id')
        const deletedIds = await this.$store.dispatch('subdivision/delete', {
          ids,
          corporation: this.filters.corporation,
        })
        this.tableData = this.tableData.filter(d => !deletedIds.includes(d.id))

        return notify({
          type: 'success',
          message: `Successful deleted ${deletedIds.length} subdivisions`,
        })
      } catch (e) {
        return errNotify(e)
      } finally {
        this.isLoading = false
      }
    },

    async restore({ id, index }) {
      this.isLoading = true
      try {
        this.page = indexToPage(index)
        await this.$store.dispatch('subdivision/restore', {
          id,
          corporation: this.filters.corporation,
        })
        await this.load()
      } catch (e) {
        errNotify(e)
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.subdivisions-page {
  height: 100%;
  display: flex;
  flex-direction: column;

  .content {
    flex: 1;
    overflow: hidden;
  }
}
</style>
