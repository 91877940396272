import { initIdb } from '@/plugins/idb'
import i18n from '@/plugins/i18n'

export default async function routerMiddleware({ next, store }) {
  if (!store.getters.isAppLoaded) {
    try {
      await store.dispatch('setLocale', { locale: i18n.locale })

      const admin = await store.dispatch('auth/autoLogin')

      // FIXME clear
      if (admin && admin.id) {
        await Promise.all([
          initIdb(admin.id),
          store.dispatch('dependency/fetchRoles'),
          store.dispatch('dependency/fetchPaymentPlans'),
          store.dispatch('corporation/fetchForFilter'),
        ])
      }
    } catch (e) {
      console.error(e)
    }

    store.dispatch('setIsAppLoaded', true)
  }

  return next()
}
