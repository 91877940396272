import { TPage } from '../modules/core'
import { LoginPage } from '../modules/auth'
import { AdminsPage, AdminPage } from '../modules/admins'
import { CorporationsPage, CorporationPage } from '../modules/corporations'
import { PositionsPage, PositionPage } from '../modules/positions'
import { SubdivisionsPage, SubdivisionPage } from '../modules/subdivisions'
import { TestingAimsPage, TestingAimPage } from '../modules/testingAims'
import { UsersPage, UserPage } from '../modules/users'
import { AnalyticsPage } from '../modules/analytics'
import { PaymentsPage } from '../modules/payments'

export const routes = [
  {
    path: '/',
    name: 'home',
    redirect: '/corporations',
  },
  {
    path: '/corporations',
    name: 'corporations',
    component: CorporationsPage,
    meta: { secure: true },
  },
  {
    path: '/corporations/new',
    name: 'corporation.new',
    component: CorporationPage,
    meta: { secure: true },
  },
  {
    path: '/corporations/:id',
    name: 'corporation.edit',
    component: CorporationPage,
    meta: { secure: true },
  },
  {
    path: '/admins',
    name: 'admins',
    component: AdminsPage,
    meta: { secure: true },
  },
  {
    path: '/admins/new',
    name: 'admin.new',
    component: AdminPage,
    props: true,
    meta: { secure: true },
  },
  {
    path: '/admins/:id',
    name: 'admin.edit',
    component: AdminPage,
    meta: { secure: true },
  },
  {
    path: '/users',
    name: 'users',
    component: UsersPage,
    meta: {
      secure: true,
    },
  },
  {
    path: '/users/new',
    name: 'user.new',
    component: UserPage,
    props: true,
    meta: { secure: true },
  },
  {
    path: '/users/:id',
    name: 'user.edit',
    component: UserPage,
    meta: {
      secure: true,
    },
  },
  {
    path: '/subdivisions',
    name: 'subdivisions',
    component: SubdivisionsPage,
    meta: { secure: true },
  },
  {
    path: '/subdivisions/new',
    name: 'subdivision.new',
    component: SubdivisionPage,
    props: true,
    meta: { secure: true },
  },
  {
    path: '/subdivisions/:id',
    name: 'subdivision.edit',
    component: SubdivisionPage,
    meta: { secure: true },
  },
  {
    path: '/positions',
    name: 'positions',
    component: PositionsPage,
    meta: { secure: true },
  },
  {
    path: '/positions/new',
    name: 'position.new',
    component: PositionPage,
    props: true,
    meta: { secure: true },
  },
  {
    path: '/positions/:id',
    name: 'position.edit',
    component: PositionPage,
    meta: { secure: true },
  },
  {
    path: '/testing-aims',
    name: 'testing-aims',
    component: TestingAimsPage,
    meta: { secure: true },
  },
  {
    path: '/testing-aims/new',
    name: 'testing-aim.new',
    component: TestingAimPage,
    props: true,
    meta: { secure: true },
  },
  {
    path: '/testing-aims/:id',
    name: 'testing-aim.edit',
    component: TestingAimPage,
    meta: { secure: true },
  },
  {
    path: '/analytics',
    name: 'analytics',
    component: AnalyticsPage,
    meta: { secure: true },
  },
  {
    path: '/payments',
    name: 'payments',
    component: PaymentsPage,
  },
  {
    path: '/login',
    name: 'login',
    component: LoginPage,
  },
  {
    path: '/ttest',
    name: 'ttest',
    component: TPage,
    meta: { secure: true },
  },
  { path: '*', redirect: { name: 'home' } },
]
