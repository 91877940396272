<template>
  <section class="analytics-page">
    <h1>Analytics</h1>
    <pre>{{ user }}</pre>
  </section>
</template>

<script>
export default {
  name: 'AnalyticsPage',
  data() {
    return {
      user: null,
    }
  },
  created() {
    this.load()
  },
  methods: {
    async load() {
      // TODO: implements it
    },
  },
}
</script>

<style lang="scss" scoped></style>
