<template>
  <el-row class="corporation-filters table-filter">
    <div class="mr-1">
      <el-button plain type="danger" @click="$emit('clear-filters')">
        {{$t('buttons.clearFilters')}}
      </el-button>
    </div>

    <div>
      <el-input
        class="item"
        clearable
        :placeholder="$t('filters.search')"
        :value="value.search"
        @input="emit('search', $event)"
      />

      <el-select
        clearable
        filterable
        :placeholder="$t('filters.status')"
        :value="value.status"
        @change="emit('status', $event)"
      >
        <el-option
          v-for="us in statuses"
          :key="us.id"
          :label="us.name"
          :value="us.id"
        />
      </el-select>

      <el-date-picker
        class="item"
        unlink-panels
        type="daterange"
        range-separator="-"
        :start-placeholder="$t('el.datepicker.start')"
        :end-placeholder="$t('el.datepicker.end')"
        value-format="yyyy-MM-dd"
        :value="value.dateRange"
        @input="emit('dateRange', $event)"
      />

      <el-select
        clearable
        filterable
        :placeholder="$t('filters.paymentPlan')"
        :value="value.paymentPlanId"
        @change="emit('paymentPlanId', $event)"
      >
        <el-option
          v-for="pp in paymentPlans"
          :key="pp.id"
          :label="pp.name"
          :value="pp.id"
        />
      </el-select>

      <el-checkbox
        border
        :value="value.showDeleted"
        @change="emit('showDeleted', $event)"
      >
        {{ $t('filters.showDeleted') }}
      </el-checkbox>
    </div>
  </el-row>
</template>

<script>
import { getDefault } from '@/services'

export default {
  name: 'CorporationFilters',

  props: {
    value: { type: Object, required: true },
  },

  data() {
    return {
      statuses: getDefault('activityStatuses'),
    }
  },

  computed: {
    paymentPlans() {
      return this.$store.getters['dependency/paymentPlans']
    },
  },

  methods: {
    emit(name, value) {
      this.$emit(`update:${name}`, value)
    },
  },
}
</script>
