<template>
  <section class="user-form">
    <h3>
      {{ `${formData.id ? $t('form.name.edit') : $t('form.name.create')}` }}
    </h3>
    <el-form
      ref="formData"
      label-width="200px"
      size="small"
      :rules="rules"
      :model="formData"
      @submit.native.prevent="submit"
    >
      <el-form-item prop="corporationId" :label="$t('form.corporation.label')">
        <el-select
          filterable
          placeholder="Corporation"
          v-model="formData.corporationId"
        >
          <el-option
            v-for="c in corporations"
            :key="c.id"
            :label="c.name"
            :value="c.id"
          />
        </el-select>
      </el-form-item>

      <el-form-item prop="email" :label="$t('form.email.label')">
        <el-input
          name="Email"
          type="email"
          :placeholder="$t('form.email.placeholder')"
          v-model="formData.email"
        />
      </el-form-item>

      <el-form-item prop="lastName" :label="$t('form.lastName.label')">
        <el-input
          name="Last Name"
          :placeholder="$t('form.lastName.placeholder')"
          v-model="formData.lastName"
        />
      </el-form-item>

      <el-form-item prop="firstName" :label="$t('form.firstName.label')">
        <el-input
          name="First Name"
          :placeholder="$t('form.firstName.placeholder')"
          v-model="formData.firstName"
        />
      </el-form-item>

      <el-form-item prop="birthday" :label="$t('form.birthday.label')">
        <el-date-picker
          type="date"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          :placeholder="$t('el.datepicker.placeholder')"
          v-model="formData.birthday"
          :default-value="defaultDate"
        />
      </el-form-item>

      <el-form-item prop="gender" :label="$t('form.gender.label')">
        <el-radio-group v-model="formData.gender">
          <el-radio label="male">{{ $t('form.gender.male') }}</el-radio>
          <el-radio label="female">{{ $t('form.gender.female') }}</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item prop="subdivisionId" :label="$t('form.subdivision.label')">
        <el-select
          filterable
          placeholder="Subdivision"
          v-model="formData.subdivisionId"
        >
          <el-option
            v-for="s in subdivisions"
            :key="s.id"
            :label="s.fullName"
            :value="s.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item prop="positionId" :label="$t('form.position.label')">
        <el-select
          filterable
          placeholder="Position"
          v-model="formData.positionId"
        >
          <el-option
            v-for="p in positions"
            :key="p.id"
            :label="p.name"
            :value="p.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item prop="testingAimId" :label="$t('form.testingAim.label')">
        <el-select
          filterable
          placeholder="Testing aim"
          v-model="formData.testingAimId"
        >
          <el-option
            v-for="ta in testingAims"
            :key="ta.id"
            :label="ta.name"
            :value="ta.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        prop="targetPositionId"
        :label="$t('form.targetPosition.label')"
      >
        <el-select
          filterable
          placeholder="Target position"
          v-model="formData.meta.targetPositionId"
        >
          <el-option
            v-for="tp in positions"
            :key="tp.id"
            :label="tp.name"
            :value="tp.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item prop="notes" :label="$t('form.notes.label')">
        <el-input
          type="textarea"
          placeholder="Please input"
          :autosize="{ minRows: 2 }"
          v-model="formData.notes"
        >
        </el-input>
      </el-form-item>

      <el-form-item class="actions">
        <router-link class="mr-1" :to="{ name: 'users' }">
          <el-button plain type="danger">{{ $t('buttons.cancel') }}</el-button>
        </router-link>

        <el-button
          plain
          type="primary"
          class="submit-btn"
          :disabled="!dirty"
          @click.prevent.native="submit"
        >
          {{ $t('buttons.save') }}
        </el-button>
      </el-form-item>
    </el-form>
  </section>
</template>

<script>
import { cloneDeep, errNotify } from '@/services'

export default {
  name: 'UserForm',

  props: {
    user: { type: Object, required: true },
  },

  data() {
    return {
      formData: null,
      dirty: false,
      defaultDate: new Date(Date.now()),
      rules: {
        email: [
          {
            required: !this.user.id,
            message: 'Email is required',
            trigger: 'blur',
          },
          {
            type: 'email',
            message: 'Please input correct email address.',
            trigger: 'blur',
          },
        ],
        firstName: [
          {
            required: true,
            message: 'First name is required',
            trigger: 'blur',
          },
        ],
        lastName: [
          {
            required: true,
            message: 'Last name is required',
            trigger: 'blur',
          },
        ],
        gender: [
          {
            required: true,
            message: 'Gender is required',
            trigger: 'blur',
          },
        ],
        subdivisionId: [
          {
            required: true,
            message: 'Subdivision is required',
            trigger: 'blur',
          },
        ],
      },
    }
  },

  created() {
    this.formData = cloneDeep(this.user)
  },

  computed: {
    corporations() {
      return this.$store.getters['corporation/corporations']
    },
    subdivisions() {
      return this.$store.getters['subdivision/subdivisions']
    },
    positions() {
      return this.$store.getters['position/positions']
    },
    currentEvents() {
      return this.$store.getters['currentEvent/currentEvents']
    },
    testingAims() {
      return this.$store.getters['testingAim/testingAims']
    },
  },

  watch: {
    'formData.corporationId': async function handler(corporationId) {
      try {
        if (!this.user.id) {
          this.formData.subdivisionId = null
          this.formData.positionId = null
          this.formData.testingAimId = null
        }
        await this.loadCompanyDependencies(corporationId)
      } catch (e) {
        console.error(e)
      }
    },
    formData: {
      handler(newVal, oldVal) {
        this.dirty = newVal === oldVal
      },
      deep: true,
    },
  },

  methods: {
    async loadCompanyDependencies(corporation) {
      return Promise.all([
        this.$store.dispatch('subdivision/fetchForFilter', { corporation }),
        this.$store.dispatch('position/fetchForFilter', { corporation }),
        this.$store.dispatch('currentEvent/fetchForFilter'),
        this.$store.dispatch('testingAim/fetchForFilter', { corporation }),
      ])
    },

    submit() {
      this.$refs.formData.validate(async valid => {
        if (!valid) return
        try {
          await this.$emit('submit', this.formData)
        } catch (e) {
          errNotify(e)
        } finally {
          this.isLoading = false
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.user-form {
  text-align: left;

  .el-date-editor,
  .el-select {
    width: 100%;
  }

  .actions {
    margin-top: 1rem;

    .submit-btn {
      min-width: 200px;
      margin-top: 20px;
    }
  }
}
</style>
