<template>
  <section class="positions-page">
    <el-row class="mb-2" justify="space-between">
      <PositionFilters
        class="positions-filters"
        :value="filters"
        v-bind.sync="filters"
        @clear-filters="clearFilters"
      />

      <div class="ml-1" v-if="!deleted">
        <el-button-group>
          <el-button
            plain
            type="success"
            icon="el-icon-plus"
            @click="$router.push({
              name: 'position.new',
              params: { corporationId: filters.corporation },
            })"
          >
            {{ $t('buttons.add') }}
          </el-button>

          <el-button
            plain
            type="danger"
            icon="el-icon-delete"
            :disabled="!selected.length"
            @click="handleDelete"
          >
            {{ $t('buttons.delete') }}
          </el-button>
        </el-button-group>
      </div>
    </el-row>

    <div class="content mb-1">
      <PositionsTable
        :index="index"
        :value="tableData"
        :selected="selected"
        @selection-change="selected = $event"
        @restore="restore"
      />
    </div>

    <el-row justify="center">
      <el-pagination
        background
        hide-on-single-page
        layout="prev, pager, next"
        :total="total"
        :current-page.sync="page"
        :page-size="PAGINATION_SIZE"
        @current-change="showCurrentPage"
      />
    </el-row>

    <div class="mt-1" v-if="total">
      {{ $t('tableLabels.total') }}: {{ total }}
    </div>
  </section>
</template>

<script>
import {
  errNotify,
  notify,
  getIndex,
  pluck,
  indexToPage,
  debounce,
  env,
  getDefault,
} from '@/services'
import PositionFilters from './components/PositionFilters'
import PositionsTable from './components/PositionsTable'

export default {
  name: 'PositionsPage',

  components: {
    PositionFilters,
    PositionsTable,
  },

  data() {
    return {
      isLoading: true,
      filters: {},
      tableData: [],
      selected: [],
      total: 0,
      PAGINATION_SIZE: env.PAGINATION_SIZE,
      page: 1,
      deleted: false,
    }
  },

  computed: {
    index() {
      return getIndex(this.page)
    },
  },

  watch: {
    filters: {
      deep: true,
      handler() {
        if (this.isLoading) return null
        return this.applyFilters()
      },
    },
  },

  async mounted() {
    try {
      this.applyFilters = debounce(this.applyFilters, env.DEBOUNCE_TIME)
      await this.load()
    } catch (e) {
      console.error(e)
    }
  },

  methods: {
    async restoreFilters() {
      try {
        const filters = await this.$store.dispatch('filters/get', {
          key: 'positionFilters',
        })
        if (!filters) return null
        this.$set(this, 'filters', filters)
        return filters
      } catch (e) {
        return console.error(e)
      }
    },

    async storeFilters() {
      try {
        await this.$store.dispatch('filters/set', {
          key: 'positionFilters',
          value: this.filters,
        })
      } catch (e) {
        console.error(e)
      }
    },

    async load() {
      this.isLoading = true
      try {
        const filters = await this.restoreFilters()
        if (!filters) return null

        const params = {
          limit: this.PAGINATION_SIZE,
          offset: (this.page - 1) * this.PAGINATION_SIZE,
          filters: this.filters,
        }

        const {
          total,
          results,
        } = await this.$store.dispatch('position/fetchAll', { params })

        this.total = total
        this.tableData = results
        return results
      } catch (e) {
        return errNotify(e)
      } finally {
        this.isLoading = false
      }
    },

    async applyFilters() {
      this.isLoading = true
      try {
        this.page = 1
        this.deleted = this.filters.showDeleted

        await this.storeFilters()
        await this.load()
      } catch (e) {
        errNotify(e)
      } finally {
        this.isLoading = false
      }
    },

    async clearFilters() {
      this.isLoading = true
      try {
        const { corporation } = this.filters
        this.filters = {
          ...getDefault('positionFilters'),
          corporation,
        }

        this.page = 1
        this.deleted = false
        await this.storeFilters()
        await this.load()
      } catch (e) {
        errNotify(e)
      } finally {
        this.isLoading = false
      }
    },

    async showCurrentPage(page) {
      try {
        this.page = page
        await this.load()
      } catch (e) {
        errNotify(e)
      } finally {
        this.isLoading = false
      }
    },

    async handleDelete() {
      // TODO: check if position used in corporation
      try {
        const ids = pluck(this.selected, 'id')
        const deletedIds = await this.$store.dispatch('position/delete', {
          ids,
          corporation: this.filters.corporation,
        })
        this.tableData = this.tableData.filter(d => !deletedIds.includes(d.id))

        notify({
          type: 'success',
          message: `Successful deleted ${deletedIds.length} positions`,
        })
      } catch (e) {
        errNotify(e)
      } finally {
        this.isLoading = false
      }
    },

    async restore({ id, index }) {
      this.isLoading = true
      try {
        this.page = indexToPage(index)
        await this.$store.dispatch('position/restore', {
          id,
          corporation: this.filters.corporation,
        })
        await this.load()
      } catch (e) {
        errNotify(e)
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.positions-page {
  height: 100%;
  display: flex;
  flex-direction: column;

  .content {
    flex: 1;
    overflow: hidden;
  }
}
</style>
