import { getDefault } from '@/services'
import { setFiltersByAdminId, getFiltersByAdminId } from '@/plugins/idb'

export default {
  actions: {
    async get({ rootGetters, dispatch }, { key }) {
      const superAdmin = rootGetters['auth/superAdmin']

      if (!superAdmin || !superAdmin.id) {
        await dispatch('auth/logout', null, { root: true })
        return rootGetters.router.push({ name: 'login' })
      }

      const defaultFilters = getDefault(key)

      try {
        const filters = await getFiltersByAdminId(superAdmin.id, key)
        if (!filters) {
          await setFiltersByAdminId(superAdmin.id, key, defaultFilters)
        }

        return filters || defaultFilters
      } catch (e) {
        console.error(e)
        return setFiltersByAdminId(superAdmin.id, key, defaultFilters)
      }
    },

    async set({ rootGetters }, { key, value }) {
      const superAdmin = rootGetters['auth/superAdmin']
      try {
        return await setFiltersByAdminId(superAdmin.id, key, value)
      } catch (e) {
        console.error(e)
      }
    },
  },
}
