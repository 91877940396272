import { GET, POST, PUT } from '@/plugins/http'

export default {
  state: () => ({
    positions: [],
  }),
  mutations: {
    setPositions: (state, payload) => { state.positions = payload },
  },
  actions: {
    async fetchAll(ctx, payload) {
      const {
        data: { total, results },
      } = await GET('/positions', payload)
      return { total: Number(total), results }
    },

    async fetchForFilter({ commit }, { corporation }) {
      const {
        data: { results },
      } = await GET('/positions', {
        params: {
          limit: 9999,
          filters: { corporation },
        },
      })

      commit('setPositions', results)
      return results
    },

    async get(ctx, { id }) {
      const { data } = await GET(`/positions/${id}`)
      return data
    },

    async create(ctx, payload) {
      const { data } = await POST('/positions', payload)
      await ctx.dispatch('fetchForFilter', {
        corporation: payload.corporationId,
      })
      return data
    },

    async update(ctx, payload) {
      const { data } = await PUT(`/positions/${payload.id}`, payload)
      await ctx.dispatch('fetchForFilter', {
        corporation: payload.corporationId,
      })
      return data
    },

    async delete(ctx, { ids, corporation }) {
      const { data: deleted_ids } = await POST('/positions/delete', { ids })
      await ctx.dispatch('fetchForFilter', { corporation })
      return deleted_ids
    },

    async restore(ctx, { id, corporation }) {
      const { data } = await GET(`/positions/restore/${id}`)
      await ctx.dispatch('fetchForFilter', { corporation })
      return data
    },
  },
  getters: {
    positions: state => state.positions,
  },
}
