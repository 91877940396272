import { cloneDeep } from './functions'
import env from './envConstants'

const corporation = {
  name: '',
  description: '',
  isActive: false,
  paymentPlanId: null,
  paymentPlan: {},
  mainSubdivisionId: null,
  mainSubdivision: {
    city: '',
    name: '',
  },
  createdAt: null,
  updatedAt: null,
  deletedAt: null,
}

const admin = {
  email: '',
  lastName: '',
  firstName: '',
  corporationId: null,
  corporation: {},
  subdivisionId: null,
  subdivision: {},
  positionId: null,
  position: {},
  role: {},
  roleId: null,
  rights: [],
  isActive: false,
  contacts: '',
  createdAt: null,
  updatedAt: null,
  deletedAt: null,
}

const user = {
  corporationId: null,
  corporation: {},
  email: '',
  firstName: '',
  lastName: '',
  // empty birthday only NULL, not a empty string!
  // ERROR: invalid input syntax for type timestamp with time zone: ""
  birthday: null,
  gender: '',
  subdivisionId: null,
  subdivision: {},
  positionId: null,
  position: {},
  currentEventId: 1,
  currentEvent: {},
  testingAimId: null,
  testingAim: {},
  testingResult: {},
  meta: {
    targetPosition: '',
  },
  notes: '',
  createdAt: null,
  updatedAt: null,
  deletedAt: null,
}

const subdivision = {
  name: '',
  city: '',
  corporationId: null,
  parentId: null,
}

const position = {
  name: '',
  corporationId: null,
  description: '',
}

const testingAim = {
  name: '',
  corporationId: null,
  description: '',
}

const corporationFilters = {
  dateRange: [],
  paymentPlanId: null,
  search: '',
  status: null,
  showDeleted: false,
}

const adminFilters = {
  corporation: env.DOTS_CORPORATION_ID,
  subdivision: null,
  position: null,
  search: '',
  status: null,
  showDeleted: false,
}

const userFilters = {
  corporation: env.DOTS_CORPORATION_ID,
  dateRange: [],
  position: null,
  search: '',
  currentEvent: null,
  testingAim: null,
  subdivision: null,
  showDeleted: false,
}

const subdivisionFilters = {
  corporation: env.DOTS_CORPORATION_ID,
  search: '',
  showDeleted: false,
  showChildren: false,
  currentId: null,
}

const positionFilters = {
  corporation: env.DOTS_CORPORATION_ID,
  search: '',
  showDeleted: false,
}

const currentEventFilters = {
  name: '',
  description: '',
}

const testingAimFilters = {
  corporation: env.DOTS_CORPORATION_ID,
  search: '',
  showDeleted: false,
}

const activityStatuses = [
  { id: true, name: 'Active' },
  { id: false, name: 'Disabled' },
]

export const defaultEntities = {
  activityStatuses,
  admin,
  adminFilters,
  corporation,
  corporationFilters,
  currentEventFilters,
  position,
  positionFilters,
  subdivision,
  subdivisionFilters,
  user,
  userFilters,
  testingAim,
  testingAimFilters,
}

export const getDefault = name => {
  const entity = defaultEntities[name]

  return entity
    ? cloneDeep(entity)
    : console.error(`THERE IS NO DEFAULT ENTITY: ${name}`)
}
