import { GET, POST, PUT } from '@/plugins/http'

export default {
  actions: {
    async fetchAll(ctx, payload) {
      const {
        data: { total, results },
      } = await GET('/users', payload)
      return { total: Number(total), results }
    },

    async get(ctx, { id }) {
      const { data: user } = await GET(`/users/${id}`)
      return user
    },

    async create(ctx, payload) {
      const { data: user } = await POST('/users', payload)
      return user
    },

    async update(ctx, payload) {
      const { data: user } = await PUT(`/users/${payload.id}`, payload)
      return user
    },

    async delete(ctx, payload) {
      const { data: ids } = await POST('/users/delete', payload)
      return ids
    },

    async restore(ctx, { id }) {
      const { data: userId } = await GET(`/users/restore/${id}`)
      return userId
    },
  },
}
