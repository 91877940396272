var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-table',{ref:"corporationsList",attrs:{"stripe":"","border":"","data":_vm.value,"height":"100%","width":"100%","row-class-name":_vm.tableRowClassName},on:{"selection-change":function($event){return _vm.$emit('selection-change', $event)}}},[_c('el-table-column',{attrs:{"type":"index","width":"50","index":_vm.index}}),_c('el-table-column',{attrs:{"type":"selection","width":"44"}}),_c('el-table-column',{attrs:{"sortable":"","min-width":"200","prop":"name","label":"Company Name","sort-method":function (a, b) { return a.name.localeCompare(b.name); }}}),_c('el-table-column',{attrs:{"prop":"description","label":"Description","min-width":"150"}}),_c('el-table-column',{attrs:{"sortable":"","width":"150","prop":"createdAt","label":"Start Date"}}),_c('el-table-column',{attrs:{"prop":"isActive","label":"Status","width":"80"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.isActive ? 'Active' : 'Disabled')+" ")]}}])}),_c('el-table-column',{attrs:{"sortable":"","prop":"users","label":"Users","width":"100","sort-method":function (a, b) { return Number(a.users) - Number(b.users); }}}),_c('el-table-column',{attrs:{"sortable":"","prop":"admins","label":"Admins","width":"100","sort-method":function (a, b) { return Number(a.admins) - Number(b.admins); }}}),_c('el-table-column',{attrs:{"sortable":"","prop":"uses_tests","label":"Tests used","width":"120","sort-method":function (a, b) { return Number(a.uses_tests) - Number(b.uses_tests); }}}),_c('el-table-column',{attrs:{"sortable":"","prop":"updatedAt","label":"Updated At","width":"150"}}),_c('el-table-column',{attrs:{"min-width":"100","prop":"paymentPlan.name","label":"Payment Plan"}}),_c('el-table-column',{attrs:{"label":"Operations","width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(!row.deletedAt)?_c('router-link',{staticClass:"mr-1",attrs:{"to":{
          name: 'corporation.edit',
          params: { id: row.id },
        }}},[_c('el-button',{attrs:{"plain":"","type":"primary","icon":"el-icon-edit"}},[_vm._v("Edit")])],1):_vm._e(),(!row.deletedAt)?_c('el-button',{attrs:{"plain":"","icon":row.isActive ? 'el-icon-video-pause' : 'el-icon-video-play',"type":row.isActive ? 'warning' : 'success'},on:{"click":function($event){return _vm.$emit('toggle-activation', row)}}},[_vm._v(" "+_vm._s(row.isActive ? 'Disable' : 'Enable')+" ")]):_vm._e(),(row.deletedAt)?_c('el-button',{attrs:{"plain":"","type":"info","icon":"el-icon-thumb"},on:{"click":function($event){return _vm.$emit('restore', { id: row.id, index: _vm.index })}}},[_vm._v(" Restore ")]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }