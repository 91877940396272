<template>
  <section class="form">
    <h3>
      {{ `${formData.id ? $t('form.name.edit') : $t('form.name.create')}` }}
    </h3>
    <el-form
      ref="formData"
      label-width="200px"
      size="small"
      :rules="rules"
      :model="formData"
      @submit.native.prevent="submit"
    >
      <el-form-item prop="corporation" :label="$t('form.corporation.label')">
        <el-select
          filterable
          placeholder="Corporation"
          v-model="formData.corporationId"
        >
          <el-option
            v-for="c in corporations"
            :key="c.id"
            :label="c.name"
            :value="c.id"
          />
        </el-select>
      </el-form-item>

      <el-form-item prop="name" :label="$t('form.position.name')">
        <el-input
          name="Name"
          :placeholder="$t('form.position.placeholder')"
          v-model="formData.name"
        />
      </el-form-item>

      <el-form-item prop="description" :label="$t('form.description.label')">
        <el-input
          type="textarea"
          placeholder="Please input"
          :autosize="{ minRows: 2 }"
          v-model="formData.description"
        >
        </el-input>
      </el-form-item>

      <el-form-item class="actions">
        <router-link class="mr-1" :to="{ name: 'positions' }">
          <el-button plain type="danger">{{ $t('buttons.cancel') }}</el-button>
        </router-link>

        <el-button
          plain
          type="primary"
          class="submit-btn"
          :disabled="!dirty"
          @click.prevent.native="submit"
        >
          {{ $t('buttons.save') }}
        </el-button>
      </el-form-item>
    </el-form>
  </section>
</template>

<script>
import { cloneDeep, errNotify } from '@/services'

export default {
  name: 'PositionForm',

  props: {
    position: { type: Object, required: true },
  },

  data() {
    return {
      formData: null,
      dirty: false,
      rules: {
        name: [
          {
            required: true,
            message: 'Position name is required',
            trigger: 'blur',
          },
        ],
      },
    }
  },

  created() {
    this.formData = cloneDeep(this.position)
  },

  computed: {
    corporations() {
      return this.$store.getters['corporation/corporations']
    },
  },

  watch: {
    formData: {
      handler(newVal, oldVal) {
        this.dirty = newVal === oldVal
      },
      deep: true,
    },
  },

  methods: {
    submit() {
      this.$refs.formData.validate(async valid => {
        if (!valid) return
        try {
          await this.$emit('submit', this.formData)
        } catch (e) {
          errNotify(e)
        } finally {
          this.isLoading = false
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.form {
  text-align: left;

  .el-date-editor,
  .el-select {
    width: 100%;
  }

  .actions {
    margin-top: 1rem;

    .submit-btn {
      min-width: 200px;
      margin-top: 20px;
    }
  }
}
</style>
