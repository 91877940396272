<template>
  <section class="login-page">
    <el-card>
      <h2>{{ $t('form.name.login') }}</h2>
      <el-form
        ref="formData"
        :rules="rules"
        :model="formData"
        @submit.native.prevent="submit"
      >
        <el-form-item prop="email" :label="$t('form.email.label')">
          <el-input
            name="email"
            type="email"
            :size="size"
            :placeholder="$t('form.email.placeholder')"
            v-model="formData.email"
          />
        </el-form-item>

        <el-form-item prop="password" :label="$t('form.password.label')">
          <el-input
            show-password
            name="password"
            type="password"
            autocomplete="off"
            :size="size"
            :placeholder="$t('form.password.placeholder')"
            v-model="formData.password"
          ></el-input>
        </el-form-item>

        <el-form-item class="actions">
          <el-button
            type="primary"
            class="submit-btn"
            :size="size"
            v-loading="isLoading"
            @click.prevent.native="submit"
          >
            {{ $t('buttons.login') }}
          </el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </section>
</template>

<script>
export default {
  name: 'LoginPage',

  data() {
    return {
      isLoading: false,
      size: 'medium',
      formData: {
        email: '',
        password: '',
      },
      rules: {
        email: [
          {
            required: true,
            message: 'Email is required.',
            trigger: 'blur',
          },
          {
            type: 'email',
            message: 'Please input correct email address.',
            trigger: 'blur',
          },
        ],
        password: [
          {
            required: true,
            message: 'Password is required.',
            trigger: 'blur',
          },
          {
            min: 8,
            message: 'Password\'s length should be 8 and more',
            trigger: 'blur',
          },
        ],
      },
    }
  },

  methods: {
    submit() {
      this.$refs.formData.validate(async valid => {
        if (!valid) return false
        this.isLoading = true

        try {
          const { email, password } = this.formData
          await this.$store.dispatch('auth/login', { email, password })

          await Promise.all([
            this.$store.dispatch('dependency/fetchRoles'),
            this.$store.dispatch('dependency/fetchPaymentPlans'),
            this.$store.dispatch('corporation/fetchForFilter'),
          ])

          const name = this.$route.query.redirect || 'home'
          return this.$router.push({ name })
        } catch (e) {
          console.error(e)
        } finally {
          this.isLoading = false
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.login-page {
  min-height: 100%;
  display: flex;
  width: 100%;

  .el-card {
    margin: auto;
    width: 450px;
    text-align: center;

    .actions {
      margin-top: 1rem;

      .submit-btn {
        min-width: 200px;
        margin-top: 20px;
      }
    }
  }
}
</style>
