<template>
  <section class="corporations-page">
    <el-row class="mb-2" justify="space-between">
      <CorporationFilters
        class="corporation-filters"
        :value="filters"
        v-bind.sync="filters"
        @apply-filters="applyFilters"
        @clear-filters="clearFilters"
      />

      <div class="ml-1" v-if="!deleted">
        <el-button-group>
          <el-button
            plain
            type="success"
            icon="el-icon-plus"
            @click="$router.push({ name: 'corporation.new' })"
          >
            {{ $t('buttons.add') }}
          </el-button>

          <el-button
            plain
            type="danger"
            icon="el-icon-delete"
            :disabled="!selected.length"
            @click="handleDelete"
          >
            {{ $t('buttons.delete') }}
          </el-button>
        </el-button-group>
      </div>
    </el-row>

    <div class="content mb-1">
      <CorporationsTable
        v-loading="isLoading"
        :index="index"
        :value="tableData"
        :selected="selected"
        @selection-change="selected = $event"
        @toggle-activation="toggleActivation"
        @restore="restore"
      />
    </div>

    <el-row justify="center">
      <el-pagination
        background
        hide-on-single-page
        layout="prev, pager, next"
        :total="total"
        :current-page.sync="page"
        :page-size="PAGINATION_SIZE"
        @current-change="showCurrentPage"
      />
    </el-row>

    <div class="mt-1" v-if="total">
      {{ $t('tableLabels.total') }}: {{ total }}
    </div>
  </section>
</template>

<script>
import {
  errNotify,
  formatByPattern,
  getIndex,
  indexToPage,
  notify,
  pluck,
  debounce,
  env,
  getDefault,
} from '@/services'
import CorporationFilters from './components/CorporationFilters'
import CorporationsTable from './components/CorporationsTable'

export default {
  name: 'CorporationsPage',

  components: {
    CorporationFilters,
    CorporationsTable,
  },

  data() {
    return {
      isLoading: true,
      filters: {},
      tableData: [],
      selected: [],
      total: 0,
      PAGINATION_SIZE: env.PAGINATION_SIZE,
      page: 1,
      deleted: false,
    }
  },

  computed: {
    index() {
      return getIndex(this.page)
    },
  },

  watch: {
    filters: {
      deep: true,
      handler() {
        if (this.isLoading) return null
        return this.applyFilters()
      },
    },
  },

  async mounted() {
    try {
      this.applyFilters = debounce(this.applyFilters, env.DEBOUNCE_TIME)
      await this.load()
    } catch (e) {
      console.error('mounted error: ', e)
    }
  },

  methods: {
    async restoreFilters() {
      try {
        const filters = await this.$store.dispatch('filters/get', {
          key: 'corporationFilters',
        })
        if (!filters) return null
        this.$set(this, 'filters', filters)
        return filters
      } catch (e) {
        return console.error(e)
      }
    },

    async storeFilters() {
      try {
        return await this.$store.dispatch('filters/set', {
          key: 'corporationFilters',
          value: this.filters,
        })
      } catch (e) {
        return console.error(e)
      }
    },

    async load() {
      this.isLoading = true
      try {
        const filters = await this.restoreFilters()
        if (!filters) return null

        const params = {
          limit: this.PAGINATION_SIZE,
          offset: (this.page - 1) * this.PAGINATION_SIZE,
          filters,
        }

        const {
          total,
          results,
        } = await this.$store.dispatch('corporation/fetchAll', { params })

        this.total = total
        this.tableData = this.prepareData(results)
        return this.tableData
      } catch (e) {
        return errNotify(e)
      } finally {
        this.isLoading = false
      }
    },

    prepareData(corporations) {
      return corporations.map(c => ({
        ...c,
        createdAt: formatByPattern(c.createdAt, 'yyyy-MM-dd HH:mm'),
        updatedAt: formatByPattern(c.updatedAt, 'yyyy-MM-dd HH:mm'),
      }))
    },

    async applyFilters() {
      this.isLoading = true
      try {
        this.page = 1
        this.deleted = this.filters.showDeleted

        await this.storeFilters()
        await this.load()
      } catch (e) {
        errNotify(e)
      } finally {
        this.isLoading = false
      }
    },

    async clearFilters() {
      this.isLoading = true
      try {
        this.filters = getDefault('corporationFilters')

        this.page = 1
        this.deleted = false
        await this.storeFilters()
        await this.load()
      } catch (e) {
        errNotify(e)
      } finally {
        this.isLoading = false
      }
    },

    async showCurrentPage(page) {
      this.isLoading = true
      try {
        this.page = page
        await this.load()
      } catch (e) {
        errNotify(e)
      } finally {
        this.isLoading = false
      }
    },

    async toggleActivation({ id, isActive }) {
      this.isLoading = true
      try {
        await this.$store.dispatch('corporation/update', {
          id,
          isActive: !isActive,
        })
        await this.load()

        notify({
          type: 'success',
          title: 'Success',
          message: `Corporation ${
            isActive ? 'Disabled' : 'Enabled'
          } successfully`,
        })
      } catch (e) {
        errNotify(e)
      } finally {
        this.isLoading = false
      }
    },

    async handleDelete() {
      this.isLoading = true
      const ids = pluck(this.selected, 'id')

      try {
        const deletedIds = await this.$store.dispatch('corporation/delete', {
          ids,
        })
        this.tableData = this.tableData.filter(d => !deletedIds.includes(d.id))

        notify({
          type: 'success',
          message: `Successful deleted ${deletedIds.length} corporations`,
        })
      } catch (e) {
        errNotify(e)
      } finally {
        this.isLoading = false
      }
    },

    async restore({ id, index }) {
      this.isLoading = true
      try {
        this.page = indexToPage(index)
        await this.$store.dispatch('corporation/restore', { id })
        await this.load()
      } catch (e) {
        errNotify(e)
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.corporations-page {
  height: 100%;
  display: flex;
  flex-direction: column;

  .content {
    flex: 1;
    overflow: hidden;
  }
}
</style>
