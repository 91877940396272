<template>
  <el-row class="user-filters table-filter">
    <div class="mr-1">
      <el-button plain type="danger" @click="$emit('clear-filters')">
        {{$t('buttons.clearFilters')}}
      </el-button>
    </div>

    <div>
      <el-select
        filterable
        default-first-option
        :placeholder="$t('filters.corporation')"
        :value="value.corporation"
        @change="emit('corporation', $event)"
      >
        <el-option
          v-for="c in corporations"
          :key="c.id"
          :label="c.name"
          :value="c.id"
        />
      </el-select>

      <el-input
        class="item"
        clearable
        :placeholder="$t('filters.search')"
        :value="value.search"
        @input="emit('search', $event)"
      />

      <el-select
        clearable
        filterable
        :placeholder="$t('filters.subdivision')"
        :value="value.subdivision"
        @change="emit('subdivision', $event)"
      >
        <el-option
          v-for="s in subdivisions"
          :key="s.id"
          :label="s.fullName"
          :value="s.id"
        />
      </el-select>

      <el-select
        clearable
        filterable
        :placeholder="$t('filters.position')"
        :value="value.position"
        @change="emit('position', $event)"
      >
        <el-option
          v-for="p in positions"
          :key="p.id"
          :label="p.name"
          :value="p.id"
        />
      </el-select>

      <el-select
        clearable
        filterable
        :placeholder="$t('filters.currentEvent')"
        :value="value.currentEvent"
        @change="emit('currentEvent', $event)"
      >
        <el-option
          v-for="ce in currentEvents"
          :key="ce.id"
          :label="ce.name"
          :value="ce.id"
        />
      </el-select>

      <el-select
        clearable
        filterable
        :placeholder="$t('filters.testingAim')"
        :value="value.testingAim"
        @change="emit('testingAim', $event)"
      >
        <el-option
          v-for="ta in testingAims"
          :key="ta.id"
          :label="ta.name"
          :value="ta.id"
        />
      </el-select>

      <el-checkbox
        border
        :value="value.showDeleted"
        @change="emit('showDeleted', $event)"
      >
        {{ $t('filters.showDeleted') }}
      </el-checkbox>
    </div>
  </el-row>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'UserFilters',

  props: {
    value: { type: Object, required: true },
  },

  computed: {
    ...mapGetters({
      corporations: 'corporation/corporations',
      positions: 'position/positions',
      subdivisions: 'subdivision/subdivisions',
      currentEvents: 'currentEvent/currentEvents',
      testingAims: 'testingAim/testingAims',
    }),
  },

  methods: {
    emit(name, value) {
      this.$emit(`update:${name}`, value)
    },
  },
}
</script>
