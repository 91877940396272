import { GET, POST } from '@/plugins/http'

export default {
  state: () => ({
    superAdmin: null,
  }),
  mutations: {
    setSuperAdmin: (state, superAdmin) => { state.superAdmin = superAdmin },
  },
  actions: {
    async login({ commit }, payload) {
      const { data } = await POST('/auth/login', payload)
      commit('setSuperAdmin', data)
      return data
    },

    async autoLogin({ commit }) {
      const { data } = await GET('/auth/autologin')
      commit('setSuperAdmin', data)
      return data
    },

    async logout({ commit }) {
      await GET('/auth/logout')
      commit('setSuperAdmin', null)
    },
  },
  getters: {
    superAdmin: state => state.superAdmin,
    isAuth: state => Boolean(state.superAdmin && state.superAdmin.id),
  },
}
