<template>
  <router-link :to="{ name: 'home' }" class="the-logo">
    <img src="@/assets/img/logo.svg" alt="Digital Office Test System" />
  </router-link>
</template>

<script>
export default {
  name: 'TheLogo',
}
</script>

<style lang="scss" scoped>
img {
  display: 'block';
}
</style>
