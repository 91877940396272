import { GET } from '@/plugins/http'

export default {
  state: () => ({
    roles: [],
    paymentPlans: [],
  }),
  mutations: {
    setRoles: (state, payload) => { state.roles = payload },
    setPaymentPlans: (state, payload) => { state.paymentPlans = payload },
  },
  actions: {
    async fetchRoles({ commit }) {
      const { data: roles } = await GET('/roles')
      commit('setRoles', roles)
      return roles
    },
    async fetchPaymentPlans({ commit }) {
      const { data: paymentPlans } = await GET('/payment-plans')
      commit('setPaymentPlans', paymentPlans)
      return paymentPlans
    },
  },
  getters: {
    roles: state => state.roles,
    paymentPlans: state => state.paymentPlans,
  },
}
