<template>
  <el-container class="default-layout" v-if="$store.getters.isAppLoaded">
    <el-header>
      <el-row>
        <TheLogo />
        <NavMenu />
      </el-row>
    </el-header>

    <el-main>
      <slot />
    </el-main>

    <el-footer height="fit-content" >
      <TheFooter />
    </el-footer>
  </el-container>
</template>

<script>
import TheLogo from './components/TheLogo'
import NavMenu from './components/NavMenu'
import TheFooter from './components/TheFooter'

export default {
  name: 'DefaultLayout',

  components: {
    TheLogo,
    NavMenu,
    TheFooter,
  },
}
</script>

<style lang="scss" scoped>
.default-layout {
  height: 100vh;

  .el-main {
    /deep/ > section {
      width: 100%;
    }
  }

  .the-logo {
    width: fit-content;
    margin-right: 20px;
  }

  .nav-menu {
    width: 100%;
  }
}
</style>
