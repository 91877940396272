import { GET } from '@/plugins/http'

export default {
  state: () => ({
    currentEvents: [],
  }),
  mutations: {
    setCurrentEvents: (state, payload) => { state.currentEvents = payload },
  },
  actions: {
    async fetchForFilter({ commit }) {
      const { data: currentEvents } = await GET('/current-events')

      commit('setCurrentEvents', currentEvents)
      return currentEvents
    },
  },
  getters: {
    currentEvents: state => state.currentEvents,
  },
}
