import { GET, POST, PUT } from '@/plugins/http'

export default {
  state: () => ({
    testingAims: [],
  }),
  mutations: {
    setTestingAims: (state, payload) => { state.testingAims = payload },
  },
  actions: {
    async fetchAll(ctx, payload) {
      const {
        data: { total, results },
      } = await GET('/testing-aims', payload)
      return { total: Number(total), results }
    },

    async fetchForFilter({ commit }, { corporation }) {
      const {
        data: { results },
      } = await GET('/testing-aims', {
        params: {
          limit: 9999,
          filters: { corporation },
        },
      })

      commit('setTestingAims', results)
      return results
    },

    async get(ctx, { id }) {
      const { data } = await GET(`/testing-aims/${id}`)
      return data
    },

    async create(ctx, payload) {
      const { data } = await POST('/testing-aims', payload)
      await ctx.dispatch('fetchForFilter', {
        corporation: payload.corporationId,
      })
      return data
    },

    async update(ctx, payload) {
      const { data } = await PUT(`/testing-aims/${payload.id}`, payload)
      await ctx.dispatch('fetchForFilter', {
        corporation: payload.corporationId,
      })
      return data
    },

    async delete(ctx, { ids, corporation }) {
      const { data: deleted_ids } = await POST('/testing-aims/delete', { ids })
      await ctx.dispatch('fetchForFilter', { corporation })
      return deleted_ids
    },

    async restore(ctx, { id, corporation }) {
      const { data } = await GET(`/testing-aims/restore/${id}`)
      await ctx.dispatch('fetchForFilter', { corporation })
      return data
    },
  },
  getters: {
    testingAims: state => state.testingAims,
  },
}
