<template>
  <el-table
    stripe
    border
    ref="testingAimList"
    height="100%"
    width="100%"
    :data="value"
    :row-class-name="tableRowClassName"
    @selection-change="$emit('selection-change', $event)"
  >
    <el-table-column type="index" :index="index" width="50" />
    <el-table-column type="selection" width="44" />
    <el-table-column
      sortable
      prop="name"
      label="Name"
      min-width="100"
      :sort-method="(a, b) => a.name.localeCompare(b.name)"
    />
    <el-table-column prop="description" label="Description" min-width="100" />
    <el-table-column label="Operations" width="280">
      <template slot-scope="{ row }">
        <router-link
          class="mr-1"
          v-if="!row.deletedAt"
          :to="{
            name: 'testing-aim.edit',
            params: { id: row.id },
          }"
        >
          <el-button plain type="primary" icon="el-icon-edit">Edit</el-button>
        </router-link>

        <el-button
          plain
          type="info"
          icon="el-icon-thumb"
          v-if="row.deletedAt"
          @click="$emit('restore', { id: row.id, index })"
        >
          Restore
        </el-button>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
export default {
  name: 'TestingAimsTable',

  props: {
    index: { type: Number, required: true },
    value: { type: Array, required: true },
    selected: { type: Array, required: true },
  },

  methods: {
    tableRowClassName: ({ row }) => (
      row.deletedAt ? 'deleted-row' : 'success-row'
    ),
  },
}
</script>

<!--TODO: move to global-->
<style lang="scss" scoped>
/deep/ .deleted-row {
  color: #f56c6c;
}

/deep/ .warning-row {
  color: #999;
}

/deep/ .success-row {
  color: #333;
}
</style>
