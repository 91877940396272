import { GET, POST, PUT } from '@/plugins/http'

export default {
  actions: {
    async fetchAll(ctx, payload) {
      const {
        data: { total, results },
      } = await GET('/admins', payload)
      return { total: Number(total), results }
    },

    async get(ctx, { id }) {
      const { data: admin } = await GET(`/admins/${id}`)
      return admin
    },

    async create(ctx, payload) {
      const { data: admin } = await POST('/admins', payload)
      return admin
    },

    async update(ctx, payload) {
      const { data: admin } = await PUT(`/admins/${payload.id}`, payload)
      return admin
    },

    async delete(ctx, payload) {
      const { data: ids } = await POST('/admins/delete', payload)
      return ids
    },

    async restore(ctx, { id }) {
      const { data: adminId } = await GET(`/admins/restore/${id}`)
      return adminId
    },
  },
}
