import Vue from 'vue'
import VueRouter from 'vue-router'
import { get } from '@/services'
import { routes } from './routes'
import store from '../store'
import routerMiddleware from '../middlewares/routerMiddleware'
import isAuthMiddleware from '../middlewares/isAuthMiddleware'
import middlewarePipeline from './middlewarePipeline'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace

const router = new VueRouter({
  mode: 'history',
  routes,
})

// avoided redundant navigation to current location.
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(e => e)
}

VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(e => e)
}

router.beforeEach((to, from, next) => {
  const middleware = [routerMiddleware]

  if (get(to, 'meta.secure')) {
    middleware.push(isAuthMiddleware)
  }

  middleware.push(...get(to, 'meta.middleware', []))

  const context = {
    to, from, next, store,
  }

  return middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1),
  })
})

export default router
