<template>
  <el-table
    stripe
    border
    ref="usersList"
    height="100%"
    width="100%"
    :data="value"
    :row-class-name="tableRowClassName"
    @selection-change="$emit('selection-change', $event)"
  >
    <el-table-column type="index" :index="index" width="50" />

    <el-table-column type="selection" width="44" />

    <el-table-column
      sortable
      min-width="200"
      prop="lastName"
      label="Last name"
      :sort-method="(a, b) => a.lastName.localeCompare(b.lastName)"
    />

    <el-table-column prop="firstName" label="First name" min-width="180" />

    <el-table-column prop="email" label="Email" min-width="180" />

    <el-table-column prop="position.name" label="Position" width="180" />

    <el-table-column
      prop="subdivision.fullName"
      label="Subdivision"
      min-width="180"
    />

    <el-table-column label="Operations" width="280">
      <!-- TODO: implement operations -->
      <template slot-scope="{ row }">
        <router-link
          class="mr-1"
          v-if="!row.deletedAt"
          :to="{
            name: 'user.edit',
            params: { id: row.id },
          }"
        >
          <el-button plain type="primary" icon="el-icon-edit">Edit</el-button>
        </router-link>

        <el-button
          plain
          type="info"
          icon="el-icon-thumb"
          v-if="row.deletedAt"
          @click="$emit('restore', { id: row.id, index })"
        >
          Restore
        </el-button>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
export default {
  name: 'UsersTable',

  props: {
    index: { type: Number, required: true },
    value: { type: Array, required: true },
    selected: { type: Array, required: true },
  },

  methods: {
    tableRowClassName: ({ row }) => (
      row.deletedAt ? 'deleted-row' : 'success-row'
    ),
  },
}
</script>

<!--TODO: move to global-->
<style lang="scss" scoped>
/deep/ .deleted-row {
  color: #f56c6c;
}

/deep/ .success-row {
  color: #333;
}
</style>
