import { GET, POST, PUT } from '@/plugins/http'

export default {
  state: () => ({
    corporations: [],
  }),
  mutations: {
    setCorporations: (state, payload) => { state.corporations = payload },
  },
  actions: {
    async fetchAll(ctx, payload) {
      const {
        data: { total, results },
      } = await GET('/corporations', payload)
      return { total: Number(total), results }
    },

    async fetchForFilter({ commit }) {
      const {
        data: { results },
      } = await GET('/corporations', {
        params: {
          limit: 9999,
        },
      })

      commit('setCorporations', results)
      return results
    },

    async get(ctx, { id }) {
      const { data: corporation } = await GET(`/corporations/${id}`)
      return corporation
    },

    async create(ctx, payload) {
      const { data: corporation } = await POST('/corporations', payload)
      await ctx.dispatch('fetchForFilter')
      return corporation
    },

    async update(ctx, payload) {
      const { data: corporation } = await PUT(
        `/corporations/${payload.id}`,
        payload,
      )
      await ctx.dispatch('fetchForFilter')
      return corporation
    },

    async delete(ctx, payload) {
      const { data: ids } = await POST('/corporations/delete', payload)
      await ctx.dispatch('fetchForFilter')
      return ids
    },

    async restore(ctx, { id }) {
      const { data: corporationId } = await GET(`/corporations/restore/${id}`)
      await ctx.dispatch('fetchForFilter')
      return corporationId
    },
  },
  getters: {
    corporations: state => (state.corporations || []).map(({ id, name }) => ({
      id,
      name,
    })),
  },
}
