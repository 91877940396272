<template>
  <section class="page">
    <div v-if="isLoading">isLoading</div>
    <el-tabs v-else type="border-card">
      <el-tab-pane>
        <span slot="label"><i class="el-icon-notebook-2"></i> Profile </span>
        <TestingAimForm :testingAim="testingAim" @submit="submit" />
      </el-tab-pane>
      <el-tab-pane v-if="$route.params.id" label="Actions history">
        <span slot="label"><i class="el-icon-time"></i> Actions history </span>
        Actions history
      </el-tab-pane>
    </el-tabs>
  </section>
</template>

<script>
import { notify, errNotify, getDefault } from '@/services'
import TestingAimForm from './components/TestingAimForm'

export default {
  name: 'TestingAimPage',

  props: {
    corporationId: { type: Number, required: false },
  },

  components: {
    TestingAimForm,
  },

  data() {
    return {
      isLoading: true,
      testingAim: null,
    }
  },

  created() {
    this.load()
  },

  methods: {
    async load() {
      this.isLoading = true

      const defaultTestingAim = {
        ...getDefault('testingAim'),
        corporationId: this.corporationId,
      }

      try {
        this.testingAim = this.$route.params.id
          ? await this.$store.dispatch('testingAim/get', {
            id: this.$route.params.id,
          })
          : defaultTestingAim
      } catch (e) {
        this.testingAim = defaultTestingAim
        errNotify(e)
      } finally {
        this.isLoading = false
      }
    },

    async submit(formData) {
      this.isLoading = true
      try {
        // TODO: implements update only changed fields
        this.testingAim = formData.id
          ? await this.$store.dispatch('testingAim/update', formData)
          : await this.$store.dispatch('testingAim/create', formData)

        notify({
          type: 'success',
          title: 'Success',
          message: `Status ${formData.name} ${
            formData.id ? 'updated' : 'created'
          } successfully`,
        })

        await this.$router.push({ name: 'testing-aims' })

        return this.testingAim
      } catch (e) {
        return errNotify(e)
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.page {
  min-height: 100%;
  display: flex;
  width: 100%;
  margin: 0 auto;
}
</style>
