<template>
  <el-row class="testing-aim-filters table-filter">
    <div class="mr-1">
      <el-button plain type="danger" @click="$emit('clear-filters')">
        {{$t('buttons.clearFilters')}}
      </el-button>
    </div>

    <div>
      <el-select
        filterable
        default-first-option
        :placeholder="$t('filters.corporation')"
        :value="value.corporation"
        @change="emit('corporation', $event)"
      >
        <el-option
          v-for="c in corporations"
          :key="c.id"
          :label="c.name"
          :value="c.id"
        />
      </el-select>

      <el-input
        class="item"
        clearable
        :placeholder="$t('filters.search')"
        :value="value.search"
        @input="emit('search', $event)"
      />

      <el-checkbox
        border
        :value="value.showDeleted"
        @change="emit('showDeleted', $event)"
      >
        {{ $t('filters.showDeleted') }}
      </el-checkbox>
    </div>
  </el-row>
</template>

<script>
export default {
  name: 'TestingAimFilters',

  props: {
    value: { type: Object, required: true },
  },

  computed: {
    corporations() {
      return this.$store.getters['corporation/corporations']
    },
  },

  methods: {
    emit(name, value) {
      this.$emit(`update:${name}`, value)
    },
  },
}
</script>
